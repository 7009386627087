
<nb-card>
    <nb-card-header>Document <a (click)="close()" ><i class="fa fa-window-close" style="margin-left: 70%;" aria-hidden="true"></i> </a></nb-card-header>
    <nb-card-body>
        <div *ngIf = "fileDetails?.extension !='pdf'">
            <img [src]="fileDetails?.src" height=":800px;" width="100%;" alt="Random first slide">
        </div>
        <div class="pdf-container" *ngIf = "fileDetails?.extension =='pdf'">
            <pdf-viewer [src]="fileDetails?.src"
            [render-text]="true"
            [original-size]="false"
            style="width: 400px; height: 500px"
            ></pdf-viewer>
        </div>
       
    </nb-card-body>
    <nb-card-footer></nb-card-footer>
  </nb-card>