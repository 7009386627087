<nb-card status="success">
    <nb-card-header >
      <div style="text-align: center;">
        Verification Application
      </div>
    </nb-card-header> 
    <nb-card-body>
      <div class="row" style="justify-content: center;padding-top: 15px; height: 110px;">
          <button nbButton  style="height: 50px;width: 130px;background-image: linear-gradient(#40dc7e, #8A7FFF);background-repeat: repeat-x" (click)="dashboardRoutes()">
              <h4 > <b> Go </b> </h4>
          </button>
      </div>
    </nb-card-body>
</nb-card>
