<nb-card>
    <nb-card-header status="success">
      <div class="row">
        <div class="col-xl-6 col-md-6 col-sm-6 col-6">
        <label style="font-size: 140%;">Total Applications </label>
        </div>
      </div>
    </nb-card-header>
    <nb-card-body>
      <form [formGroup]="searchForm">
        <div class="row">
        <div class="col-md-4">
          <label>Application Id</label><br>
          <input nbInput formControlName="idCtrl"  placeholder="Application Id" 
            [ngClass]="{'form-control-danger': searchForm.controls.idCtrl.invalid && (searchForm.controls.idCtrl.dirty || searchForm.controls.idCtrl.touched)}">   
          </div>
      <div class="col-md-4">
          <div class="form-group">
            <label>Name</label><br>
            <input nbInput formControlName="nameCtrl"
            [(ngModel)]="name" placeholder="name"
            [ngClass]="{'form-control-danger': searchForm.controls.nameCtrl.invalid && (searchForm.controls.nameCtrl.dirty || searchForm.controls.nameCtrl.touched)}">
        </div>
        </div>
  
        <div class="col-md-4">
          <div class="form-group">
            <label>Email ID</label><br>
            <input nbInput formControlName="emailCtrl"
            [(ngModel)]="email" placeholder="email"
            [ngClass]="{'form-control-danger': searchForm.controls.emailCtrl.invalid && (searchForm.controls.emailCtrl.dirty || searchForm.controls.emailCtrl.touched)}">
          </div>
        </div>
  
        <div class="col-md-4">
          <button nbButton (click)="search()">Search</button>
        </div>
        </div>
      </form>
  
  
        <br>
      <div class="row">
          <div class="col-lg-6 ">
            <mat-form-field class="example-full-width">
              <input matInput type="text" [value]="filterText" [placeholder]="filterPlaceholder"
                [formControl]="filterInput" />
              <button mat-button *ngIf="filterText" matSuffix mat-icon-button aria-label="Clear" (click)="filterText=''">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
          </div>
          <!-- <div class="col-lg-3 ">
            <mat-form-field>
              <mat-select placeholder="select Year" [(ngModel)]="selectedYear" name="selectedYear" id="yearCtrl"
                (ngModelChange)="filterYear($event)">
                <mat-option value="2019">2019-2020</mat-option>
                <mat-option value="2020">2020-2021</mat-option>
              </mat-select>
            </mat-form-field>
          </div> -->
        </div>
      <div class="wrapper">
        <button style="float: right;" nbButton status="success" (click)="downloadExcel('Total')">
          <div style="font-size: 86%;">
            Download Excel
          </div>
        </button>
        <h5><b>Total student count: {{studentLength?.totalLength}} </b></h5><br/><br/>
        <!-- <h5><b>Filtered student count: </b></h5>{{studentdata?.length}} -->
        <div class="Rtable Rtable--5cols Rtable--collapse">
          <div class="Rtable-row Rtable-row--head">
            <div class="Rtable-cell date-cell column-heading">Application Id</div>
            <div class="Rtable-cell date-cell column-heading">Full Name</div>
            <div class="Rtable-cell topic-cell column-heading">Email Address</div>
            <div class="Rtable-cell date-cell column-heading">Application Tracker</div>
            <div class="Rtable-cell date-cell column-heading">Application Status</div>
            <div class="Rtable-cell date-cell column-heading">Application Date</div>
            <!-- <div class="Rtable-cell date-cell column-heading">Service type</div> -->

            <div class="Rtable-cell date-cell column-heading">Action</div>
          </div>
          
          <div class="Rtable-row is-striped" *ngFor="let student of studentdata | filter:{name:filterText,email:filterText,id:filterText} : false    | paginate: { itemsPerPage: 10, totalItems: totalLength, currentPage:p }">

            <div class="Rtable-cell date-cell">
              <div class="Rtable-cell--heading">Application Id</div>
              <div class="Rtable-cell--content date-content">{{student?.id}}
              </div>
            </div>
            <div class="Rtable-cell date-cell">
              <div class="Rtable-cell--heading">Full Name</div>
              <div class="Rtable-cell--content date-content">{{student?.name}}</div>
            </div>
            <div class="Rtable-cell topic-cell">
              <div class="Rtable-cell--heading">Email Address</div>
              <div class="Rtable-cell--content title-content">{{student?.email}}</div>
            </div>
            <div class="Rtable-cell date-cell">
              <div class="Rtable-cell--heading">Application Tracker</div>
              <div class="Rtable-cell--content date-content">{{student?.tracker}}</div>
            </div>
            <div class="Rtable-cell date-cell">
              <div class="Rtable-cell--heading">Application Status</div>
              <div class="Rtable-cell--content date-content">{{student?.status}}</div>
            </div>
            <div class="Rtable-cell date-cell">
              <div class="Rtable-cell--heading">Application Date</div>
              <div class="Rtable-cell--content date-content">{{student?.application_date}}</div>
            </div>
            <!-- <div class="Rtable-cell date-cell">
           
              <div class="Rtable-cell--heading">Service Type</div>
              <div class="Rtable-cell--content date-content" *ngIf="student.servicetype==436 || student.servicetype==298">Courier {{student.servicetype}}</div>
              <div class="Rtable-cell--content date-content" *ngIf="student?.servicetype==200">Pickup {{student.servicetype}}</div>
              <div class="Rtable-cell--content date-content" *ngIf="student?.servicetype==253">Mail {{student.servicetype}}</div>


            </div> -->
            <div class="Rtable-cell date-cell">
              <div class="Rtable-cell--heading">Action</div>
              <div class="Rtable-cell--content date-content">
                <i class="fa ion-person" status="info" nbPopover="View More" nbPopoverMode="hover"
                (click)="handleClick(student.user_id,student.id)"></i><br>
                <button nbButton outline size="small" *ngIf="student.otp_verify==0 && student.email_verification_token ==0" status="primary" (click)="verifyOtp('0', '0', student.id);">Verify</button>
                <button nbButton outline size="small" status="danger" *ngIf="student.userStatus == 'active'" (click)="status('inactive',student.id);">inactive</button>
                <button nbButton outline size="small" status="success" *ngIf="student.userStatus == 'inactive'" (click)="status('active',student.id);">active</button>
              </div>
            </div>
            <!-- <div class="Rtable-cell date-cell">
              <div class="Rtable-cell--heading">Profile Completeness</div>
              <div class="Rtable-cell--content date-content">
                  <nb-progress-bar [value]="student.profile_completeness" outline status="info" [displayValue]="true">
                  </nb-progress-bar>
              </div>
            </div> -->
          </div>
        </div>
        <div *ngIf="studentLength?.totalLength == 0">
          <span style="color : red; font-size: 18px;">No Data Available</span>
        </div>
      </div>
      <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
    </nb-card-body>
  </nb-card>