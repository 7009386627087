<nb-card>
    <nb-card-header>

        <div class="modal-body">
            <form novalidate="" class="ng-pristine ng-invalid ng-touched"><label class="form-label2 color-theme">Enter
                    your query</label><textarea placeholder="Enter your query" formcontrolname="query" id="query"
                    class="form-control form-input ng-pristine ng-invalid ng-touched"></textarea>
            
            </form>
            <button class="btn bg-theme color-white mt-3">Submit</button>
        </div>
    </nb-card-header>
</nb-card> 